<template>
  <v-menu
    v-model="modal"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="18rem"
    nudge-top="20"
    z-index="20"
  >
    <template v-slot:activator="{ on, attrs }">
      <k-input
        v-model="computedDateFormatted"
        v-bind="attrs"
        v-on="on"
        readonly
        :invalid="invalid"
        class="tw-inline-block tw-w-28"
        :class="inputClass"
      />
    </template>
    <v-date-picker v-model="date" @input="handleInput" color="#0ea66d" locale="sk-SK">
      <v-btn text color="primary" @click="clear" small>{{ $t('global.actions.delete') }}</v-btn>
      <v-spacer />
      <v-btn text color="primary" @click="today" small>{{ $t('global.time.today') }}</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'date',
    event: 'input'
  },
  props: {
    // Custom
    invalid: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    inputClass: {
      type: String
    }
  },
  data() {
    return {
      modal: false,
      date: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.date = value
        }
      }
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    locale() {
      return this.$store.getters.appLocale
    }
  },
  methods: {
    today() {
      this.date = new Date().toISOString().substr(0, 10)
      this.handleInput()
    },
    clear() {
      this.date = null
      this.handleInput()
    },
    handleInput() {
      this.$emit('input', this.date)
      this.modal = false
    },
    formatDate(date) {
      if (!date) return null

      const d = new Date(date)

      // const [year, month, day] = date.split("-");

      const day = d.toLocaleDateString(this.locale, { day: 'numeric' })
      const month = d.toLocaleDateString(this.locale, { month: 'short' })
      const year = d.toLocaleDateString(this.locale, { year: 'numeric' })

      return `${ day } ${ month } ${ year }`
    }
  }
}
</script>

<style></style>
